import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { getCropedAccountName, getCropedResourceId } from './utils/cropText';
import { useShellRootContext } from '../../contexts/ShellRoot';
import * as S from './styles';
import * as T from './types';

import {
  Button,
  RadioButton,
  ProgressIndicator,
  RadioButtons,
  Select
} from '@veneer/core';
import createId from './utils/createId';
import logger from './utils/logger';
import AuthContextEnum from '../../enums/AuthContextEnum';
import createTenantSelectorPlaceholder from './utils/createTenantSelectPlaceholder';

const tenantsRadioLimit = 5;
const TenantSelectorPage: T.TenantSelectorPageType = ({
  fullscreen = false,
  finishTenantSelector,
  errorCallback,
  preventReload = false,
  applicationStatusManager
}) => {
  const { interfaces, t } = useShellRootContext() || {};

  const { sessionInterface, navigation, tenantHandlerInterface, theme } =
    interfaces?.v1 || {};

  const themeMode = theme?.getUserThemeMode?.();

  const tenantWrapper = useMemo(
    () =>
      tenantHandlerInterface?.getNextUnproccessedTenant() ||
      tenantHandlerInterface?.getNextUnproccessedTenant(AuthContextEnum.tenant),
    [tenantHandlerInterface]
  );
  const ContentWrapper = useMemo(
    () => (fullscreen ? S.Background : React.Fragment),
    [fullscreen]
  );

  const { strategy: tenantStrategy, authContext } = tenantWrapper;

  const tenantId = tenantHandlerInterface?.getTenantId(authContext);
  const [isChangingDomain, setIsChangingDomain] = useState(false);
  const [selectedDomainId, setSelectedDomainId] = useState(tenantId);
  const [tenants, setTenants] = useState([] as T.TenantSelectorData[]);
  const [tenantTypes, setTenantTypes] = useState(
    {} as Record<string, T.TenantVisualizationType>
  );
  const [loadErrorPage, setLoadErrorPage] = useState(false);

  const [tenantPlaceholderList, setTenantPlaceholderList] = useState([]);

  const TENANT_SELECTOR_PLACEHOLDER_VALUE = 'tenantSelectorPlaceholderValue';

  const setTenant = useCallback(
    async (tenant: string) => {
      const isTenantPlaceholderSelected = tenant?.startsWith(
        TENANT_SELECTOR_PLACEHOLDER_VALUE
      );
      if (isTenantPlaceholderSelected) {
        const tenantPlaceholderPath = navigation.createHref({
          pathname: tenantPlaceholderList?.find((t) => {
            return t?.value === tenant && t?.route;
          })?.route
        });
        navigation.push(tenantPlaceholderPath);
      } else {
        setIsChangingDomain(true);
        try {
          await tenantHandlerInterface.setTenant({
            tenantId: tenant,
            authContext,
            preventReload
          });
          await finishTenantSelector?.();
        } catch (error) {
          logger?.error?.('Error on setTenant call', error);
          if (errorCallback) {
            return await errorCallback(error);
          }
          setLoadErrorPage(true);
        }
        setIsChangingDomain(false);
      }
    },
    [
      navigation,
      tenantPlaceholderList,
      tenantHandlerInterface,
      authContext,
      finishTenantSelector,
      preventReload,
      errorCallback
    ]
  );

  useEffect(() => {
    (async () => {
      const tenantDataList = await tenantStrategy?.getTenantList?.();
      const orderedTenantDataList =
        tenantDataList.sort((a, b) => a?.name.localeCompare(b?.name)) || [];
      if (orderedTenantDataList.length === 0) {
        const fallbackPath = navigation.createHref({
          pathname: tenantStrategy.getFallback()
        });
        navigation.push(fallbackPath);
      }

      const haveTenants = orderedTenantDataList?.length > 0;

      const portalOnboardingList =
        tenantHandlerInterface?.getPortalOnboardingList();

      const portalOnboardingFilteredList = portalOnboardingList?.filter(
        (portalOnboarding) =>
          portalOnboarding?.tenantSelectorPlaceholder?.label?.length > 0
      );

      const hasPrimaryTenant = orderedTenantDataList?.some((tenant) => {
        return portalOnboardingFilteredList?.some(
          (portalOnboarding) =>
            portalOnboarding?.primaryTenantType?.some((t: string) => {
              return (
                t.toLowerCase()?.trim() === tenant?.type?.toLowerCase()?.trim()
              );
            })
        );
      });

      const tenantPlaceholderList = createTenantSelectorPlaceholder(
        portalOnboardingFilteredList,
        t,
        TENANT_SELECTOR_PLACEHOLDER_VALUE
      );

      const hasTenantPlaceholder = tenantPlaceholderList?.length > 0;
      setTenantPlaceholderList(tenantPlaceholderList);

      const addTenantPlaceholder =
        hasTenantPlaceholder && haveTenants && !hasPrimaryTenant;

      const newTenantTypes = {};

      const tenantSelectDataList = orderedTenantDataList?.map(
        ({ name, id, type }) => {
          newTenantTypes[id] = { type, name };
          const label = `${getCropedAccountName(name)} ${getCropedResourceId(
            id
          )}`;
          return {
            label,
            value: id
          };
        }
      );

      if (addTenantPlaceholder) {
        tenantSelectDataList?.push(...tenantPlaceholderList);
      }

      setTenantTypes(newTenantTypes);
      setTenants(tenantSelectDataList || []);
    })();
  }, [tenantStrategy, navigation, tenantHandlerInterface, t]);

  useEffect(() => {
    if (tenants?.length === 1) {
      (async () => {
        await setTenant(tenants[0]?.value);
      })();
    }
    setSelectedDomainId(tenants[0]?.value);
  }, [tenants, tenantTypes, setTenant]);

  useEffect(() => {
    if (loadErrorPage) {
      applicationStatusManager?.showErrorPage?.({
        message: {
          label:
            'Please click on retry, if it still not work you may need to restart it'
        },
        buttons: {
          list: [
            {
              label: 'Sign Out',
              onClick: async () => {
                await sessionInterface?.logout();
              }
            }
          ]
        }
      });
      return () => applicationStatusManager?.hideAll?.();
    }
  }, [loadErrorPage, applicationStatusManager, sessionInterface]);

  const isSelect = (tenants?.length || 0) > tenantsRadioLimit;
  const firstItem = tenants.length ? tenants[0]?.value : undefined;

  async function onConfirmation() {
    if (selectedDomainId) {
      return setTenant(selectedDomainId);
    }
  }

  if (tenants?.length <= 1) {
    return <div data-testid={createId('tenantSelectorSuspense')}></div>;
  }

  return (
    <ContentWrapper themeMode={themeMode}>
      <S.Container
        data-testid={createId('container')}
        appearance="dropShadow"
        content={
          <S.Content isselect={isSelect}>
            <S.TitleWrapper>
              <S.Title
                data-testid={createId('welcome_back')}
                themeMode={themeMode}
              >
                {t('nav.tenant-selector.page.title', 'Welcome Back')}
              </S.Title>
              <S.SubTitle themeMode={themeMode}>
                {t(
                  'nav.tenant-selector.page.subtitle',
                  'Select an organization to sign in.'
                )}
              </S.SubTitle>
            </S.TitleWrapper>
            <S.FormDiv isselect={isSelect}>
              {isSelect ? (
                <Select
                  disabled={isChangingDomain}
                  options={tenants}
                  visibleOptions={4}
                  defaultValue={[firstItem]}
                  clearIcon={false}
                  value={selectedDomainId ? [selectedDomainId] : undefined}
                  onChange={(e) => setSelectedDomainId(e?.value as string)}
                  data-testid={createId('organizationListSelect')}
                />
              ) : (
                <RadioButtons
                  name="radiobutton_default"
                  defaultValue={firstItem}
                  value={selectedDomainId}
                  onChange={(e, value) => setSelectedDomainId(value)}
                  data-testid={createId('organizationList')}
                >
                  {tenants?.map?.((props, index) => (
                    <RadioButton
                      key={props.value}
                      disabled={isChangingDomain}
                      data-testid={createId('organization_' + index)}
                      {...props}
                    />
                  ))}
                </RadioButtons>
              )}
              <Button
                disabled={!selectedDomainId || isChangingDomain}
                onClick={() => onConfirmation()}
                appearance="primary"
                data-testid={createId('continue_button')}
              >
                {isChangingDomain ? (
                  <ProgressIndicator
                    color="gray4"
                    appearance="circular"
                  />
                ) : (
                  t('nav.tenant-selector.page.button', 'Continue')
                )}
              </Button>
            </S.FormDiv>
          </S.Content>
        }
      />
    </ContentWrapper>
  );
};

export default TenantSelectorPage;
